import { auth, db } from "../../firebase";
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";

const userId = localStorage.getItem("uid");

export const handleLogin = async ({
  email,
  password,
  setLoading,
  navigate,
  setError,
}) => {
  setLoading(true);
  try {
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        navigate("/");
      })
      .catch((error) => {
        setError(error);
      });
  } catch (error) {
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const handleSignUp = async ({
  email,
  password,
  setLoading,
  navigate,
  setError,
}) => {
  try {
    setLoading(true);
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        await setDoc(doc(db, "users", user.uid), {
          email: email,
          status: "active",
          role: "member",
        });
        navigate("/");
      })
      .catch((error) => {
        setError(error);
      });
  } catch (error) {
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const handleSignOut = async ({ navigate }) => {
  await signOut(auth)
    .then(() => {
      localStorage.removeItem("userKey", null);
      navigate("/");
    })
    .catch((error) => {
      // An error happened.
    });
};

export const handleResetPassword = async ({
  email,
  setLoading,
  setError,
  setSuccess,
}) => {
  try {
    setLoading(true);
    await sendPasswordResetEmail(auth, email)
      .then((userCredential) => {
        setSuccess(true);
      })
      .catch((error) => {
        setError(error);
      });
  } catch (error) {
    setError(error);
  } finally {
    setLoading(false);
  }
};

export const handleLinkSignIn = async ({
  email,
  setLinkLoading,
  setEmailSendSuccess,
  setError,
}) => {
  try {
    setLinkLoading(true);
    await sendSignInLinkToEmail(auth, email, {
      // this is the URL that we will redirect back to after clicking on the link in mailbox
      // url: "http://localhost:3000/",
      url: "https://hem-tech.web.app/",
      handleCodeInApp: true,
    })
      .then((res) => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        localStorage.setItem("emailForSignIn", email);
        setEmailSendSuccess(true);
      })
      .catch((error) => {
        setError(error);
      });
  } catch (error) {
    setError(error);
  } finally {
    setLinkLoading(false);
  }
};

export const changeUserPassword = async ({
  email,
  currentPassword,
  newPassword,
  setShowChangePasswordModal,
  setErrorMessage,
}) => {
  try {
    const user = auth.currentUser;
    const credential = promptForCredentials(email, currentPassword); // Function to get user credentials
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
    setShowChangePasswordModal(false);
  } catch (error) {
    console.error("Error updating password: ", error);
    setErrorMessage(error.message ? error.message : error);
    throw error.message ? error.message : error;
  }
};

const promptForCredentials = (userEmail, currentPassword) => {
  const email = userEmail; //prompt("Please enter your email:");
  const password = currentPassword; //prompt("Please enter your current password:");
  return EmailAuthProvider.credential(email, password);
};

export const updateUser = async (firstName, lastName) => {
  const userRef = doc(db, "users", userId);
  try {
    await updateDoc(userRef, {
      firstName,
      lastName,
    });
  } catch (error) {
    console.error("Error updating user data: ", error);
    throw error;
  }
};

export const getUserData = async (userId) => {
  const userRef = doc(db, "users", userId);
  try {
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
    console.error("No such document!");
    return null;
  } catch (error) {
    console.error("Error getting user data: ", error);
    return null;
  }
};
