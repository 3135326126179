import React, { useState } from "react";
import { updateUser } from "../../config/database/authentication";
import { useSelector } from "react-redux";
import { getUser } from "../../store/apps/user";
import toast from "react-hot-toast";
import { ImCross } from "react-icons/im";

const MyProfile = ({ showModal, setShowModal, setShowChangePasswordModal }) => {
  const userData = useSelector(getUser);
  const email = localStorage.getItem("email");

  const [firstName, setFirstName] = useState(userData?.firstName || "");
  const [lastName, setLastName] = useState(userData?.lastName || "");

  const handleUpdateProfile = async () => {
    toast.promise(
      async () => {
        await updateUser(firstName, lastName);
        setShowModal(false);
      },
      {
        loading: "Loading",
        success: "User data update successfully",
        error: "An error occurred while updating user data",
      }
    );
  };

  const handleChangePassword = async () => {
    setShowModal(false);
    setShowChangePasswordModal(true);
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full my-6 mx-auto max-w-3xl px-4">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl md:text-3xl font-semibold text-center">
                    My Profile
                  </h3>
                  <button
                    className="absolute right-0 text-red-500 background-transparent font-bold uppercase px-7 py-2 text-sm md:text-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    <ImCross />
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-4 flex-auto m-4">
                  <div className="relative mb-4" data-te-input-wrapper-init>
                    <h3 className="text-[16px] font-poppins font-[400] leading-[18px] mb-1">
                      Email
                    </h3>
                    <input
                      type="text"
                      value={email}
                      disabled
                      className="peer block min-h-[auto] w-full font-poppins font-[400] rounded text-login_text bg-[#EBEBE4] px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three cursor-not-allowed"
                    />
                  </div>

                  <div className="relative mb-4" data-te-input-wrapper-init>
                    <h3 className="text-[16px] font-poppins font-[400] leading-[18px] mb-1">
                      First Name
                    </h3>
                    <input
                      type="text"
                      value={firstName}
                      className="peer block min-h-[auto] w-full font-poppins font-[400] rounded text-login_text bg-transparent px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three"
                      placeholder="Enter your first name"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="relative mb-4" data-te-input-wrapper-init>
                    <h3 className="text-[16px] font-poppins font-[400] leading-[18px] mb-1">
                      Last Name
                    </h3>
                    <input
                      type="text"
                      value={lastName}
                      className="peer block min-h-[auto] w-full font-poppins font-[400] rounded text-login_text bg-transparent px-3 py-[0.05rem] leading-[2.15] outline-none transition-all duration-200 ease-linear  border-[2px] border-theme_three placeholder-theme_three"
                      placeholder="Enter your last name"
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                  <div
                    className="mb-0 flex items-center justify-end font-poppins font-[400] cursor-pointer"
                    onClick={() => handleChangePassword()}
                  >
                    Change Password?
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                  {/* <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button> */}
                  <div></div>
                  <button
                    className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => handleUpdateProfile()}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default MyProfile;
