import React, { useEffect, useRef } from "react";

const Typewriter = ({
  texts,
  speed = 100,
  startDelay = 500,
  onWordTyped,
  setReadingAiResponse,
  typingText,
}) => {
  const elementRef = useRef(null);

  useEffect(() => {
    if (!elementRef.current) return;

    let iIndex = 0;
    let iTextPos = 0;
    let iArrLength = texts[0].length;
    let sContents = "";
    let iRow;
    let isCompleted = false;
    setReadingAiResponse &&
      typingText &&
      typingText !== "Typing..." &&
      setReadingAiResponse(true);

    const typewriter = () => {
      if (isCompleted) return;

      sContents = "";
      iRow = Math.max(0, iIndex - 20);
      while (iRow < iIndex) {
        sContents += texts[iRow++] + "<br />";
      }
      if (elementRef.current) {
        elementRef.current.innerHTML =
          sContents +
          texts[iIndex].substring(0, iTextPos) +
          (iTextPos === iArrLength ? "" : "_");
        onWordTyped();
      }
      if (iTextPos++ === iArrLength) {
        iTextPos = 0;
        iIndex++;
        // onWordTyped();
        if (iIndex !== texts.length) {
          setTimeout(typewriter, startDelay);
        } else {
          isCompleted = true;
          setReadingAiResponse &&
            typingText &&
            typingText !== "Typing..." &&
            setReadingAiResponse(false);
        }
      } else {
        setTimeout(typewriter, speed);
      }
    };

    setTimeout(typewriter, startDelay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [texts, speed, startDelay, onWordTyped]);

  return <span ref={elementRef} id="typedtext"></span>;
};

export default Typewriter;
