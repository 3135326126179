import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";

async function getDataFromUrl(url) {
  try {
    const assistantsCollection = collection(db, "assistants");
    const q = query(assistantsCollection, where("url", "==", url));
    const querySnapshot = await getDocs(q);
    const data = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const docData = { id: doc.id, ...doc.data() };
        return docData;
      })
    );
    return data;
  } catch (error) {
    console.error("Error fetching data from URL: ", error.message);
    throw error;
  }
}

export { getDataFromUrl };
