import React, { useState, useEffect, useRef } from "react";
import {
  getChatAnswer,
  fetchSpeech,
  getImageCreationAnswer,
  getChatAnswerWithFile,
  getImageVariationAnswer,
  createAssistantThread,
  getAnswerFromThreads,
  getAnswerFromFileThreads,
  getFineTuneJobRetrieve,
} from "../../api/chat";
import {
  getChatConfigs,
  getImageConfigs,
  getSpeechConfigs,
} from "../../config/database/chat";
import {
  SEND_ICON,
  SCROLL_TO_BOTTOM,
  MICROPHONE,
  Image,
  ChatIcon,
  CROSS_ICON,
  FILE_ICON,
  CLIP_ICON,
} from "../../assets/Images";
import "./chat.css";
import Header from "../../components/Header";
import ChatMessage from "../../components/ChatMessage";
import micGif from "../../assets/Images/mic.gif";
import { useSelector } from "react-redux";
import { getChat } from "../../store/apps/chat";
import { getUserAppearance } from "../../config/database/appearance";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import { FormControl, MenuItem, Select } from "@mui/material";
import { getDataFromUrl } from "../../config/database/assistants";
import {
  getDataFromFineTuneUrl,
  updateFineTuningData,
} from "../../config/database/fineTuning";
import { useNavigate } from "react-router-dom";
import { TopBar } from "../../components/TopBar/TopBarHeader";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

const Chat = () => {
  const chat = useSelector(getChat);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isMicOn, setIsMicOn] = useState(true);
  const [chatConfigData, setChatConfigData] = useState();
  const [divHeight, setDivHeight] = useState(0);
  const [speechConfigData, setSpeechConfigData] = useState({});
  const [speakingStates, setSpeakingStates] = useState({});
  const [isLongPress, setIsLongPress] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [isReSpeaking, setIsReSpeaking] = useState({});
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const [appearance, setAppearance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isImage, setIsImage] = useState(false);
  const [file, setFile] = useState(null);
  const [tempFile, setTempFile] = useState(null);

  const [imageConfigData, setImageConfigData] = useState();

  const [inputSpeechLanguage, setInputSpeechLanguage] = useState("en-US");

  const [assistantData, setAssistantData] = useState("");
  console.log("assistantData",assistantData);
  const [assistantId, setAssistantId] = useState("");
  const [threadId, setThreadId] = useState("");

  const [fineTuneModel, setFineTuneModel] = useState("");
  const [fineTuneInstruction, setFineTuneInstruction] = useState("");

  const navigate = useNavigate();

  const validImageTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/webp",
    "image/gif",
  ];

  const backgroundStyle =
    appearance &&
    appearance.assistantChatBackgroundColor &&
    appearance.userChatBackgroundColor
      ? {
          background: `linear-gradient(to right, ${appearance.assistantChatBackgroundColor}, ${appearance.userChatBackgroundColor})`,
        }
      : {
          background: "linear-gradient(to right, #26A69A, #2196F3",
        };

  const divRef = useRef(null);
  const messageContainerRef = useRef(null);
  const recognitionRef = useRef();
  const isScreenSmaller = window.innerWidth <= 450;

  useEffect(() => {
    const fetchSpeechConfigData = async () => {
      const configData = await getSpeechConfigs();
      setSpeechConfigData(configData);
    };

    fetchSpeechConfigData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchAppearanceData = async () => {
      const appearanceData = await getUserAppearance();
      setAppearance(appearanceData);
      setLoading(false);
    };
    fetchAppearanceData();
  }, []);

  useEffect(() => {
    if (chat.length > 0 && Object.keys(speechConfigData).length > 0) {
      setMessages([...messages, chat[1]]);
      speakMessage(chat[1], 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat, speechConfigData]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } =
      messageContainerRef.current;
    setShowScrollButton(scrollHeight - scrollTop > clientHeight);
  };

  useEffect(() => {
    const fetchChatConfig = async () => {
      const data = await getChatConfigs();
      setChatConfigData(data);
    };

    fetchChatConfig();
  }, []);

  useEffect(() => {
    const fetchImageConfig = async () => {
      const data = await getImageConfigs();
      setImageConfigData(data);
    };

    fetchImageConfig();
  }, []);

  useEffect(() => {
    if (messageContainerRef.current) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    checkValidUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkValidUrl = async () => {
    const pathname = decodeURIComponent(window.location.pathname);

    const urlParts = pathname.split("/").filter((part) => part);
    if (urlParts.length >= 2) {
      const route = urlParts[urlParts.length - 2]; // second last part
      const routeId = urlParts[urlParts.length - 1]; // last part

      if (route === "assistants") {
        const assistant = await getDataFromUrl(routeId);
        if (assistant.length > 0) {
          setAssistantId(assistant[0]?.assistantId);
          setAssistantData(assistant[0]);
          const thread = await createAssistantThread();
          setThreadId(thread?.id);
        } else {
          navigate("/invalidLink");
        }
      } else if (route === "fine-tuning") {
        const fineTuning = await getDataFromFineTuneUrl(routeId);

        if (fineTuning.length > 0) {
          if (fineTuning[0]?.fineTuningStatus === "in-progress") {
            const fineTuneJob = await getFineTuneJobRetrieve(
              fineTuning[0]?.fineTuningId
            );

            const updatedStatus =
              fineTuneJob.status === "succeeded" ||
              fineTuneJob.status === "failed"
                ? fineTuneJob.status
                : "in-progress";

            if (updatedStatus !== "in-progress") {
              await updateFineTuningData(fineTuning[0]?.id, {
                fineTuningStatus: updatedStatus,
                fineTuneModel: fineTuneJob.fine_tuned_model,
              });
              const currentFineTuneData = fineTuning[0];
              currentFineTuneData.fineTuningStatus = updatedStatus;
              currentFineTuneData.fineTuneModel = fineTuneJob.fine_tuned_model;
              setFineTuneModel(currentFineTuneData?.fineTuneModel);
              setFineTuneInstruction(currentFineTuneData?.instructions);
            } else {
              navigate("/invalidLink", {
                state: { message: "fine tuning in progress" },
              });
            }
          } else {
            setFineTuneModel(fineTuning[0]?.fineTuneModel);
            setFineTuneInstruction(fineTuning[0]?.instructions);
          }
        }
      }
    }
  };

  const scrollToBottom = () => {
    messageContainerRef.current.scrollTop =
      messageContainerRef.current.scrollHeight;
  };

  const sendMessage = async () => {
    if (!inputValue.trim()) return;

    if (
      messages.length > 1 &&
      messages[messages.length - 1].content === "Typing ..."
    ) {
      return;
    }

    if (file && file !== null) {
      setTempFile(file);
    }

    setIsReSpeaking({});
    setSpeakingStates({});

    const userMessage = {
      role: "user",
      content: inputValue.trim(),
      file: file && file !== null ? file : undefined,
    };
    // Include user message
    const newMessages = [...messages, userMessage];
    // typing indicator
    const typingMessage = {
      role: "assistant",
      content: isImage ? "Generating ..." : "Typing ...",
      typing: true,
    };

    setMessages([...newMessages, typingMessage]);
    setInputValue("");

    let relevantMessages = newMessages.slice(-7);
    const filteredArray = relevantMessages.filter(
      (item) => item.responseType !== "image"
    );
    relevantMessages = filteredArray;

    try {
      let response;
      if (assistantId && threadId) {
        if ((file && file !== null) || (tempFile && tempFile !== null)) {
          const formData = new FormData();
          formData.append("file", file && file !== null ? file : tempFile);
          Object.entries({
            messages: inputValue.trim(),
            assistantId,
            threadId,
          }).forEach(([key, value]) => {
            formData.append(key, JSON.stringify(value));
          });
          setFile(null);
          setTempFile(null);
          response = await getAnswerFromFileThreads(formData);
        } else {
          response = await getAnswerFromThreads({
            messages: inputValue.trim(),
            assistantId,
            threadId,
          });
        }
      } else if (isImage) {
        response = await getImageCreationAnswer({
          messages: inputValue.trim(),
          ...imageConfigData,
        });
      } else if ((file && file !== null) || (tempFile && tempFile !== null)) {
        const formData = new FormData();
        formData.append("file", file && file !== null ? file : tempFile);
        Object.entries({
          messages: relevantMessages,
          ...chatConfigData,
          fineTuneModel,
          fineTuneInstruction,
        }).forEach(([key, value]) => {
          formData.append(key, JSON.stringify(value));
        });
        setFile(null);
        response = await getChatAnswerWithFile(formData);
      } else {
        response = await getChatAnswer({
          messages: relevantMessages,
          ...chatConfigData,
          fineTuneModel,
          fineTuneInstruction,
        });
      }

      const assistantReply = {
        role: "assistant",
        content:
          assistantId && threadId
            ? response.response
            : isImage
            ? response.data
            : (file && file !== null) || (tempFile && tempFile !== null)
            ? response.answer
            : response.choices[0].message.content,
        responseType: isImage && "image",
      };
      const updatedMessages = [...newMessages, assistantReply];
      setMessages(updatedMessages);
      !isImage && speakMessage(assistantReply, updatedMessages.length - 1);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        type: "error",
        content: `Sorry, an error occurred while processing your request. Please try again later.Error:Code: ${error?.response?.status} Message: ${error?.response?.data} `,
      };
      const updatedMessagesWithError = [...newMessages, errorMessage];
      setMessages(updatedMessagesWithError);
    }
  };

  const getVariationImage = async (imageUrl) => {
    const userMessage = {
      role: "user",
      content:
        imageConfigData?.numberOfImages > 1
          ? "Generating Image Variations"
          : "Generating Image Variation",
      file: file && file !== null ? file : undefined,
    };
    // Include user message
    const newMessages = [...messages, userMessage];
    // typing indicator
    const typingMessage = {
      role: "assistant",
      content: "Generating ...",
      typing: true,
    };

    setMessages([...newMessages, typingMessage]);
    setInputValue("");
    try {
      const response = await getImageVariationAnswer({
        imageUrl,
        ...imageConfigData,
      });
      const assistantReply = {
        role: "assistant",
        content: response.data,
        responseType: "image",
      };
      const updatedMessages = [...newMessages, assistantReply];
      setMessages(updatedMessages);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        type: "error",
        content: `Sorry, an error occurred while processing your request. Please try again later.Error:Code: ${error?.response?.status} Message: ${error?.response?.data} `,
      };
      const updatedMessagesWithError = [...newMessages, errorMessage];
      setMessages(updatedMessagesWithError);
    }
  };

  const speakMessage = async (message, index) => {
    if (isMicOn) {
      setSpeakingStates((prev) => ({ ...prev, [index]: true }));
      await fetchSpeech({ text: message.content, ...speechConfigData });
      setSpeakingStates((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleMic = () => {
    setIsMicOn(!isMicOn);
  };

  useEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setDivHeight(divRef.current.offsetHeight);
      }
    };

    !loading && updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [loading]);

  useEffect(() => {
    if (!("webkitSpeechRecognition" in window)) {
      console.error("Speech recognition not supported in this browser.");

      return;
    }
    const recognitionInstance = new window.webkitSpeechRecognition();
    recognitionInstance.continuous = true;
    recognitionInstance.interimResults = true;
    recognitionInstance.lang = inputSpeechLanguage; //"en-US";
    recognitionInstance.onresult = (event) => {
      let interim = "";
      let final = "";
      for (let i = 0; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          final += transcript;
        } else {
          interim += transcript;
        }
      }
      setInputValue(final + interim);
    };

    recognitionInstance.onerror = (event) => {
      console.error("Speech recognition error", event);
    };
    setRecognition(recognitionInstance);
  }, [inputSpeechLanguage]);

  useEffect(() => {
    const checkIfTouchDevice = () => {
      setIsTouchDevice(
        "ontouchstart" in window || navigator.maxTouchPoints > 0
      );
    };

    checkIfTouchDevice();
  }, []);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsLongPress(true);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);
    if (recognition) {
      recognition.start();
    }
  };

  const handleMouseUp = () => {
    setIsLongPress(false);
    document.removeEventListener("mouseup", handleMouseUp);
    document.removeEventListener("mousemove", handleMouseMove);
    if (recognition) {
      recognition.stop();
    }
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 0) {
      handleMouseUp();
    }
  };

  const handleTouchStart = (e) => {
    if (isLongPress) {
      recognitionRef.current?.stop();
      setIsLongPress(false);
      return;
    }

    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    recognitionRef.current = new SpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = inputSpeechLanguage; //"en-US";
    recognitionRef.current.onstart = function () {
      setIsLongPress(true);
    };
    recognitionRef.current.onend = function () {
      setIsLongPress(false);
    };
    recognitionRef.current.onresult = async function (event) {
      let interim = "";
      let final = "";
      for (let i = 0; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          final += transcript;
        } else {
          interim += transcript;
        }
      }

      if (window.navigator.platform.toLowerCase().includes("linux arm")) {
        setInputValue(event.results[event.results.length - 1][0].transcript);
      } else {
        setInputValue(final + interim);
      }
    };

    recognitionRef.current.start();
  };

  const handleChange = (event) => {
    setIsImage(event.target.value === "image" ? true : false);
  };

  const handleFileChange = (event) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2 MB
    if (event?.target?.files[0]?.size > maxSizeInBytes) {
      alert("File size exceeds 2 MB. Please select another file.");
      setFile(null);
      event.target.value = "";
    } else {
      event.target.files[0] ? setFile(event.target.files[0]) : setFile(null);
    }
  };

  const renderSelectedValue = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {isImage ? (
        <>
          <Image className="md:w-[30px] md:h-[30px] w-[25px] h-[25px]" />
          <p className="md:ml-[5px] hidden md:flex bg-transparent font-poppins font-[500] text-[16px] leading-tight">
            Image
          </p>
        </>
      ) : (
        <>
          <ChatIcon className="md:w-[25px] md:h-[25px] w-[20px] h-[20px]" />
          <p className="md:ml-[5px] hidden md:flex bg-transparent font-poppins font-[500] text-[16px] leading-tight">
            Chat
          </p>
        </>
      )}
    </div>
  );

  const { height } = useWindowDimensions();

  const isMicOnState = useSelector((state) => state.isMicOn.isMicOn);

  useEffect(() => {
    setIsMicOn(isMicOnState === "true" ? true : false);
  }, [isMicOnState]);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div className="flex flex-col">
          <div ref={divRef}>
            <TopBar appearance={appearance} assistantData={assistantData} />
            {/* <Header
              handleMic={handleMic}
              isMicOn={isMicOn}
              appearance={appearance}
              inputSpeechLanguage={inputSpeechLanguage}
              setInputSpeechLanguage={setInputSpeechLanguage}
            /> */}
          </div>
          <div
            className="flex flex-col pb-2"
            style={{
              ...backgroundStyle,
              height: !isTouchDevice
                ? `calc(100vh - ${divHeight}px)`
                : height / 1.077,
            }}
          >
            <div className="flex flex-grow overflow-auto w-full p-[10px] justify-center items-center ">
              <div
                style={{ background: appearance?.backgroundColor || "#c4d8e4" }}
                className="relative flex flex-col h-full w-full md:w-[70%] shadow-xl rounded-2x"
              >
                <div
                  className="chat-area m-[20px] gap-8 overflow-scroll scroll-smooth"
                  ref={messageContainerRef}
                  onScroll={handleScroll}
                >
                  {messages.map((message, index) => (
                    <React.Fragment key={index}>
                      <ChatMessage
                        message={message}
                        speechConfigData={speechConfigData}
                        isSpeaking={speakingStates[index] || false}
                        index={index}
                        setSpeakingStates={setSpeakingStates}
                        isReSpeaking={isReSpeaking[index] || false}
                        setIsReSpeaking={setIsReSpeaking}
                        isMicOn={isMicOn}
                        appearance={appearance}
                        getVariationImage={getVariationImage}
                      />
                    </React.Fragment>
                  ))}
                  {showScrollButton && (
                    <SCROLL_TO_BOTTOM
                      className="fill-black fixed bottom-[12%] right-[4%] md:right-[16%] w-[30px] h-[30px]"
                      style={{ backdropFilter: "blur(2px)" }}
                      onClick={scrollToBottom}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full justify-center items-center px-[10px]">
              <div
                style={{
                  background: appearance?.backgroundColor || "#c4d8e4",
                  height:
                    file && file.name && file !== null
                      ? validImageTypes.includes(file.type)
                        ? "145px"
                        : "100px"
                      : "50px",
                }}
                className="w-full md:w-[70%] rounded-[15px] shadow-lg flex flex-col items-center overflow-hidden border-[1px] border-black mt-0 mb-auto"
              >
                {file && file.name && file !== null && (
                  <div
                    style={{
                      height: validImageTypes.includes(file.type)
                        ? "95px"
                        : "50px",
                    }}
                    className=" ml-[10px] mr-auto mt-[10px] flex flex-row"
                  >
                    <div className="flex flex-row items-center rounded-[10px] shadow-lg border-[1px] border-black p-[5px]">
                      {validImageTypes.includes(file.type) ? (
                        <img
                          src={URL.createObjectURL(file)}
                          alt="Selected"
                          className="max-w-[75px] w-auto h-auto max-h-[75px] rounded-[5px]"
                        />
                      ) : (
                        <FILE_ICON className="w-[30px] " />
                      )}
                      <p className="ml-[6px]">{file.name}</p>
                    </div>

                    <div
                      className="ml-[-10px] mt-[-10px]"
                      onClick={() => setFile(null)}
                    >
                      <CROSS_ICON className="w-[20px] mr-[12px]" />
                    </div>
                  </div>
                )}
                <div className="flex flex-row items-center h-[50px] w-[100%]">
                  {(assistantId === "" ||
                    (assistantData &&
                      assistantData?.type === "file_search")) && (
                    <div className="relative inline-block pl-[6px]">
                      <input
                        type="file"
                        id="fileInput"
                        className="hidden"
                        accept=".csv, .pdf, .docx, .txt, .html, .js, .json, .xlsx, .png, .jpeg, .jpg, .webp, .gif, .jsonl"
                        onChange={handleFileChange}
                      />
                      <label
                        htmlFor="fileInput"
                        className="cursor-pointer flex justify-center items-center"
                      >
                        <CLIP_ICON className=" md:w-[30px] md:h-[30px] w-[25px] h-[25px]" />
                      </label>
                    </div>
                  )}

                  {!assistantId && (
                    <div className="pl-[10px] md:pl-[20px]">
                      <FormControl variant="standard" sx={{ border: "none" }}>
                        <Select
                          value={isImage ? "image" : "chat"}
                          onChange={handleChange}
                          inputProps={{ "aria-label": "Without label" }}
                          renderValue={renderSelectedValue}
                          sx={{
                            backgroundColor: "transparent",
                            "&:before": { borderBottom: "none" },
                            "&:after": { borderBottom: "none" },
                            // ml: { md: 2 },
                            mt: 0.4,
                          }}
                        >
                          <MenuItem value={"chat"} disableRipple>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <ChatIcon className="md:w-[30px] md:h-[30px] w-[25px] h-[25px] mr-[5px]" />
                              Chat
                            </div>
                          </MenuItem>
                          <MenuItem value={"image"} disableRipple>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Image className="md:w-[30px] md:h-[30px] w-[25px] h-[25px] mr-[5px]" />
                              Image
                            </div>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  )}

                  <style jsx>{`
                    input::placeholder {
                      color: ${appearance?.textColor || "#000"};
                      // font-size: ${isScreenSmaller ? "11px" : "16px"};
                    }
                  `}</style>
                  <input
                    value={inputValue}
                    style={{ color: appearance?.textColor || "#000" }}
                    className="bg-transparent font-poppins font-[500] text-[16px] w-full h-full py-2 md:pl-[12px] px-[5px] md:px-[5px] leading-tight focus:outline-none focus:shadow-outline "
                    type="text"
                    autoComplete="off"
                    placeholder="Enter your question" //"Please enter your question"
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        sendMessage();
                      }
                    }}
                  />
                  {!isTouchDevice ? (
                    <div
                      className="flex items-center justify-center mr-[5px] md:mr-[10px] cursor-pointer"
                      onMouseDown={handleMouseDown}
                      onMouseUp={handleMouseUp}
                    >
                      {isLongPress ? (
                        <img
                          src={micGif}
                          alt="mic"
                          width={30}
                          draggable="false"
                        />
                      ) : (
                        <MICROPHONE className="md:w-[30px] md:h-[30px] w-[25px] h-[25px]" />
                      )}
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="flex items-center justify-center mr-[5px] md:mr-[10px] cursor-pointer"
                      onTouchStart={handleTouchStart}
                    >
                      {isLongPress ? (
                        <div
                          className="w-[30px] h-[30px]"
                          style={{
                            background: `url(${micGif}) no-repeat center`,
                            backgroundSize: "cover",
                          }}
                        />
                      ) : (
                        <MICROPHONE className="md:w-[30px] md:h-[30px] w-[25px] h-[25px]" />
                      )}
                    </button>
                  )}
                  <div
                    className="flex items-center justify-center p-[8px] pr-[12px]"
                    onClick={sendMessage}
                  >
                    <SEND_ICON className="md:w-[25px] md:h-[25px] w-[20px] h-[20px] cursor-pointer " />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Chat;
