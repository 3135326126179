import { useClickAway } from "react-use";
import { useRef } from "react";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import { routes } from "../../routes";

export const NavMobile = ({ handleClicked, appearance }) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));

  return (
    <div ref={ref} className="lg:hidden ">
      <Hamburger
        toggled={isOpen}
        size={20}
        toggle={setOpen}
        color={appearance?.iconColor || "black"}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed left-0 shadow-4xl right-0 p-3 pt-0 z-50 md:px-[16%]"
            style={{
              background: appearance?.headerBackgroundColor || "#c4d8e4",
            }}
          >
            <ul className="grid gap-2">
              {routes.map((route, idx) => {
                const { Icon } = route;

                return (
                  <motion.li
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20,
                      delay: 0.1 + idx / 10,
                    }}
                    key={route.title}
                    style={{
                      background: `linear-gradient(to top right, #a3a3a3, ${
                        appearance?.headerBackgroundColor || "#c4d8e4"
                      }, ${appearance?.iconColor || "black"})`,
                    }}
                    className="w-full p-[0.08rem] rounded-xl "
                  >
                    <div
                      onClick={() => {
                        setOpen((prev) => !prev);
                        handleClicked(route.title, route.href);
                      }}
                      style={{
                        color: appearance?.iconColor || "black",
                        background:
                          appearance?.headerBackgroundColor || "#c4d8e4",
                      }}
                      className={
                        "flex items-center justify-between w-full p-3 rounded-xl"
                      }
                    >
                      <span className="flex gap-1 text-lg">{route.title}</span>
                      <Icon className="text-xl" />
                    </div>
                  </motion.li>
                );
              })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
