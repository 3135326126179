import { routes } from "../../routes";

export const NavDesktop = ({ handleClicked, appearance }) => {
  return (
    <>
      <ul className="hidden lg:flex lg:items-center gap-5 text-lg">
        {routes.map((route, index) => {
          const { Icon, href, title } = route;
          return (
            <li key={index}>
              <div
                style={{
                  color: appearance?.iconColor || "black",
                  cursor: "pointer",
                  transition: "color 0.3s",
                }}
                className="flex items-center gap-1"
                onClick={() => handleClicked(title, href)}
                onMouseEnter={(e) => (e.currentTarget.style.color = "#a3a3a3")}
                onMouseLeave={(e) =>
                  (e.currentTarget.style.color =
                    appearance?.iconColor || "black")
                }
              >
                <Icon />
                {title}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};
