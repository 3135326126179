import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Chat from "./pages/Chat";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import LoadingScreen from "./components/Spinner/LoadingScreen";
import ForgetPassword from "./pages/ForgetPassword";
import InvalidLink from "./pages/InvalidLink";
import Rss from "./pages/Rss";
import Forms from "./pages/Forms";
import { Provider } from "react-redux";
import { store } from "./store";
import VoiceAssistant from "./pages/voiceAssistant";
import FormRss from "./pages/FormRss";
import Choice from "./pages/choice";
import { setIsMicOn } from "./store/apps/mic";
import { getUserData } from "./config/database/authentication";
import { setUser } from "./store/apps/user";
import { Toaster } from "react-hot-toast";

function App() {
  const [isUser, setIsUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        var uid = user.uid;
        localStorage.setItem("uid", uid); // Store uid in local storage
        localStorage.setItem("email", user.email);
        setIsUser(uid);
      } else {
        localStorage.removeItem("uid"); // Remove uid from local storage
        localStorage.removeItem("email");
        setIsUser(null);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const isMicOn = localStorage.getItem("isMicOn") ?? "true";
    localStorage.setItem("isMicOn", isMicOn);
    store.dispatch(setIsMicOn(isMicOn));
  }, []);

  const getAuthToken = () => {
    setLoading(true);
    const storedUid = localStorage.getItem("uid");
    if (storedUid) {
      setIsUser(storedUid);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAuthToken();
  }, [loading]);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      const user = await getUserData(userId);
      store.dispatch(setUser(user));
    };
    if (isUser) {
      fetchUserData(isUser);
    }
  }, [isUser]);

  return (
    <>
      <Provider store={store}>
        {loading ? (
          <LoadingScreen />
        ) : (
          <Routes>
            <Route path="/" element={isUser ? <Chat /> : <Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgetpassword" element={<ForgetPassword />} />
            <Route path="/invalidLink" element={<InvalidLink />} />
            <Route path="/rss" element={<Rss />} />
            <Route path="/forms/*" element={<Forms />} />
            <Route path="/assistants/*" element={<Chat />} />
            <Route path="/fine-tuning/*" element={<Chat />} />
            <Route path="/voice-assistant" element={<VoiceAssistant />} />
            <Route path="/choice" element={<Choice />} />
            <Route path="/form-rss" element={<FormRss />} />
            <Route path="/*" element={<InvalidLink />} />
          </Routes>
        )}
        <Toaster position="top-center" reverseOrder={false} />
      </Provider>
    </>
  );
}

export default App;
