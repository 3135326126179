/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { getChatAnswer, fetchSpeech } from "../../api/chat";
import { getChatConfigs, getSpeechConfigs } from "../../config/database/chat";
import "./choice.css";
import Typewriter from "../../components/Typewriter";
import { CHECK, CANCEL } from "../../assets/Images";
import { useSelector } from "react-redux";
import { getChat } from "../../store/apps/chat";

const Choice = () => {
  const chat = useSelector(getChat);
  const [messages, setMessages] = useState([]);
  const [divHeight, setDivHeight] = useState(0);
  const [speechConfigData, setSpeechConfigData] = useState({});
  const [typewriter, setTypewriter] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isCrossHovered, setIsCrossHovered] = useState(false);
  const [readingAiResponse, setReadingAiResponse] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isCrossActive, setIsCrossActive] = useState(false);

  // Refs
  const divRef = useRef(null);
  const chatConfigDataRef = useRef(null);
  const speechConfigDataRef = useRef(null);
  const pendingMessagesRef = useRef(null);
  const scrollRef = useRef(null);

  // Speech Config Data
  useEffect(() => {
    const fetchSpeechConfigData = async () => {
      const configData = await getSpeechConfigs();
      speechConfigDataRef.current = configData;
      setSpeechConfigData(configData);
    };

    fetchSpeechConfigData();
  }, []);

  // Chat Config Data
  useEffect(() => {
    const fetchChatConfig = async () => {
      const data = await getChatConfigs();
      chatConfigDataRef.current = data;
    };

    fetchChatConfig();
  }, []);

  // Height Update
  useEffect(() => {
    const updateHeight = () => {
      if (divRef.current) {
        setDivHeight(divRef.current.offsetHeight);
      }
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    if (chat.length > 0 && Object.keys(speechConfigData).length > 0) {
      const typingMessage = {
        role: "assistant",
        content: "Typing...",
        typing: true,
      };

      setTypewriter(true);
      setReadingAiResponse(true);
      setMessages([typingMessage]);
      pendingMessagesRef.current = [...messages, ...chat];
      speakMessage(chat[chat.length - 1], chat.length - 1);
    }
  }, [chat, speechConfigData]);

  useEffect(() => {
    isActive && setIsActive(readingAiResponse);
    isCrossActive && setIsCrossActive(readingAiResponse);
  }, [readingAiResponse]);

  const sendMessage = async (data) => {
    if (!data) return;

    if (
      messages.length > 1 &&
      messages[messages.length - 1].content === "Typing..."
    ) {
      return;
    }

    const userMessage = {
      role: "user",
      content: data,
    };
    const newMessages = [...messages, userMessage];

    // Typing indicator
    const typingMessage = {
      role: "assistant",
      content: "Typing...",
      typing: true,
    };

    setMessages([typingMessage]);
    const relevantMessages = newMessages.slice(-7);

    try {
      const response = await getChatAnswer({
        messages: relevantMessages,
        ...chatConfigDataRef.current,
      });
      const assistantReply = {
        role: "assistant",
        content: response.choices[0].message.content,
      };
      const updatedMessages = [...newMessages, assistantReply];
      pendingMessagesRef.current = updatedMessages;

      speakMessage(assistantReply, updatedMessages.length - 1);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        type: "error",
        content: `Sorry, an error occurred while processing your request. Please try again later.Error:Code: ${error?.response?.status} Message: ${error?.response?.data} `,
      };
      const updatedMessagesWithError = [...newMessages, errorMessage];
      setMessages(updatedMessagesWithError);
    }
  };

  // Speak the response from server
  const speakMessage = async (message, index) => {
    await fetchSpeech(
      {
        text: message.content,
        ...speechConfigDataRef.current,
      },
      handleChunkFetched
    );
    setTypewriter(false);
  };

  const handleChunkFetched = () => {
    if (pendingMessagesRef.current) {
      setTypewriter(true);
      setMessages(pendingMessagesRef.current);
      pendingMessagesRef.current = null;
    }
  };

  const handleWordTyped = useCallback(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, []);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const handleCheck = (e) => {
    e.preventDefault();
    sendMessage("YES");
    setReadingAiResponse(true);
  };

  const handleCross = (e) => {
    e.preventDefault();
    sendMessage("NO");
    setReadingAiResponse(true);
  };

  const handleMouseDown = () => {
    setIsActive(true);
    setIsHovered(false);
  };

  const handleCloseMouseDown = () => {
    setIsCrossActive(true);
    setIsCrossHovered(false);
  };

  const typewriterComponent = useMemo(() => {
    return (
      <Typewriter
        texts={[messages[messages.length - 1]?.content]}
        speed={35}
        startDelay={0}
        onWordTyped={handleWordTyped}
        setReadingAiResponse={setReadingAiResponse}
        typingText={messages[messages.length - 1]?.content}
      />
    );
  }, [messages[messages.length - 1]?.content]);

  return (
    <div className="flex flex-col">
      <div ref={divRef}></div>
      <div
        className={`flex justify-center items-center w-full
          bg-[#414345]
          px-[5%] md:px-[10%]`}
        style={{
          height: `calc(100vh - ${divHeight}px)`,
        }}
      >
        <div className={`flex justify-center items-center flex-row w-full`}>
          <div className="flex justify-center items-center w-[50%]">
            <div
              className="w-[100%] md:max-w-[85%] max-h-[80vh] overflow-y-auto"
              ref={scrollRef}
            >
              <p
                className={`flex items-center text-light_gray text-[16px] leading-[24px] md:text-[20px] md:leading-[32px] font-poppins font-[500] mt-[20px]`}
              >
                {messages[messages.length - 1]?.content &&
                  (messages[messages.length - 1]?.content && typewriter
                    ? typewriterComponent
                    : messages[messages.length - 1]?.content)}
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center w-[50%]">
            <div className=" flex flex-col justify-center items-center gap-[20px]">
              <button
                disabled={readingAiResponse}
                id="check"
                className={`flex justify-center items-center rounded-full 
              w-[80px] h-[80px] md:w-[110px] md:h-[110px] 
              lg:w-[150px] lg:h-[150px] shadow-lg bg-[#5cb85c] 
              overflow-hidden transition-transform duration-300 
              ease-in-out transform  ${readingAiResponse ? "opacity-40" : ""} ${
                  isHovered ? "bg-[#6cbf6c]" : ""
                }`}
                style={{
                  boxShadow: isActive
                    ? `
                      rgba(0, 0, 0, 0.577) 0px 0px 0px, 
                      inset 0.5px 0.5px 4px #122412, 
                      #244924 5.2px 5.2px 8px 0px inset
                    `
                    : `
                      rgba(0, 0, 0, 0.577) 10px 10px 8px,
                      #2e5c2e -5.2px -5.2px 8px 0px inset
                    `,
                  ...(isHovered && {
                    boxShadow: `
                      rgba(0, 0, 0, 0.577) 10px 10px 8px,
                      #2e5c2e -3.2px -3.2px 8px 0px inset
                    `,
                  }),
                }}
                onMouseDown={handleMouseDown}
                onTouchStart={handleMouseDown}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleCheck}
              >
                <CHECK className="w-[30px]" />
              </button>
              <button
                disabled={readingAiResponse}
                id="cross"
                className={`flex justify-center items-center  rounded-full w-[80px] h-[80px] 
              md:w-[110px] md:h-[110px] lg:w-[150px] lg:h-[150px] shadow-lg bg-[#d9534f] overflow-hidden transition-transform duration-300 
              ease-in-out transform  ${readingAiResponse ? "opacity-40" : ""} ${
                  isCrossHovered ? "bg-[#dc6460]" : ""
                }`}
                style={{
                  boxShadow: isCrossActive
                    ? `
                    rgba(0, 0, 0, 0.577) 0px 0px 0px, 
                    inset 0.5px 0.5px 4px #2b100f, 
                    #56211f 5.2px 5.2px 8px 0px inset
                  `
                    : `
                    rgba(0, 0, 0, 0.577) 10px 10px 8px,
                    #6c2927 -5.2px -5.2px 8px 0px inset
                  `,
                  ...(isCrossHovered && {
                    boxShadow: `
                    rgba(0, 0, 0, 0.577) 10px 10px 8px,
                    #6c2927 -3.2px -3.2px 8px 0px inset
                  `,
                  }),
                }}
                onMouseDown={handleCloseMouseDown}
                onTouchStart={handleCloseMouseDown}
                onMouseEnter={() => setIsCrossHovered(true)}
                onMouseLeave={() => setIsCrossHovered(false)}
                onClick={handleCross}
              >
                <CANCEL className="w-[22px] " />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Choice;
