import { BiLogOutCircle } from "react-icons/bi";
import { RiVolumeMuteFill } from "react-icons/ri";
import { RiVolumeUpFill } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { store } from "./store";

const generateRoutes = (isMicOn) => [
  {
    title: "Logout",
    href: "#",
    Icon: BiLogOutCircle,
  },
  {
    title: isMicOn === "true" ? "Speaking" : "Mute",
    href: isMicOn === "true" ? "false" : "true",
    Icon: isMicOn === "true" ? RiVolumeUpFill : RiVolumeMuteFill,
  },
  {
    title: "My Profile",
    href: "#",
    Icon: CgProfile,
  },
];

let routes = generateRoutes(store.getState().isMicOn.isMicOn);

store.subscribe(() => {
  const isMicOn = store.getState().isMicOn.isMicOn;
  routes = generateRoutes(isMicOn);
});

export { routes };
