import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/Spinner/LoadingScreen";
import {
  addSubmissionInForms,
  getDataFromUrl,
} from "../../config/database/forms";
import { getChatConfigs } from "../../config/database/chat";
import { getChatAnswer } from "../../api/chat";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { store } from "../../store";
import { setChat } from "../../store/apps/chat";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Container,
  MenuItem,
  Paper,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ThemeProvider from "../../theme";
import { error as errorColor } from "../../theme/palette";
import { grey } from "../../theme/palette";
import { alpha } from "@mui/material/styles";

const Forms = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [validationLoading, setValidationLoading] = useState(false);
  const [formInfo, setFormInfo] = useState();
  const [chatConfigData, setChatConfigData] = useState();
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(false);

  const defaultValues = formInfo?.fieldLabels.reduce((acc, field) => {
    acc[field.labelAlias] = "";
    return acc;
  }, {});

  const createDynamicSchema = (formFields) => {
    const schemaFields = {};

    formFields
      .filter((field) => field.type !== "description box")
      .forEach((field) => {
        schemaFields[field.labelAlias] = yup
          .string()
          .required(`${field.label} is a required field`);
      });

    return yup.object().shape(schemaFields);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(
      createDynamicSchema(
        (formInfo && formInfo.fieldLabels.length > 0 && formInfo.fieldLabels) ||
          []
      )
    ),
  });

  const onSubmit = async (data) => {
    setError(false);
    const typesToRemove = new Set(
      formInfo.fieldLabels
        .filter((field) => field.type === "description box")
        .map((field) => field.label || field.labelAlias)
    );
    const filteredData = Object.keys(data).reduce((acc, key) => {
      if (!typesToRemove.has(key)) {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    setLoading(true);
    const replacedStory = formInfo?.story.replace(/{{(.*?)}}/g, (match) => {
      return data[match.replace(/[{}]/g, "")];
    });

    const userMessage = {
      role: "user",
      content: replacedStory.trim(),
    };
    const newMessages = [...messages, userMessage];
    const relevantMessages = newMessages.slice(-7);

    try {
      const response = await getChatAnswer({
        messages: relevantMessages,
        ...chatConfigData,
      });
      const assistantReply = {
        role: "assistant",
        content: response.choices[0].message.content,
      };
      const updatedMessages = [...relevantMessages, assistantReply];

      setMessages(updatedMessages);
      store.dispatch(setChat(updatedMessages));
      await addSubmissionInForms(
        formInfo?.id,
        filteredData,
        updatedMessages[0]?.content
      );
      if (formInfo?.redirectionUrl === "chat") {
        navigate("/");
      } else if (formInfo?.redirectionUrl === "choice") {
        navigate("/choice");
      } else {
        navigate("/form-rss");
      }
      setLoading(false);
    } catch (error) {
      const errorMessage = {
        role: "assistant",
        type: "error",
        content: `Sorry, an error occurred while processing your request. Please try again later.Error:Code: ${error?.response?.status} Message: ${error?.response?.data} `,
      };
      const updatedMessagesWithError = [...newMessages, errorMessage];
      setMessages(updatedMessagesWithError);
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    checkValidUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchChatConfig = async () => {
      const data = await getChatConfigs();
      setChatConfigData(data);
    };

    fetchChatConfig();
  }, []);

  const checkValidUrl = async () => {
    setValidationLoading(true);
    const form = await getDataFromUrl(
      decodeURIComponent(window.location.href).split("/").pop()
    );
    if (form.length > 0) {
      setFormInfo(form[0]);
    } else {
      navigate("/invalidLink");
    }
    setValidationLoading(false);
  };

  if (validationLoading) {
    return <LoadingScreen />;
  }

  const placeholderColor = alpha(grey[600], 0.55);

  return (
    <ThemeProvider>
      <Container
        sx={{
          py: 5,
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        {(formInfo?.totalSubmission >= formInfo?.maxSubmission ||
          formInfo?.status === "deleted") && (
          <Alert severity="error" sx={{ mb: 4 }}>
            {formInfo?.totalSubmission >= formInfo?.maxSubmission
              ? "This form has exceeded its maximum submission limit."
              : "This form has been deleted and submissions are no longer available."}
          </Alert>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 4 }}>
            Something went wrong. Please, try again later.
          </Alert>
        )}

        <Card
          sx={{
            px: 4,
            py: 5,
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            mb={3}
          >
            <Typography variant="h4">{formInfo?.title}</Typography>
          </Stack>

          <Box sx={{ mx: { xs: 0, sm: 5, md: 10, lg: 15, xl: 20 } }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {formInfo?.fieldLabels?.map((item, index) => (
                <Box key={index}>
                  {item.type === "description box" ? (
                    <>
                      <Box
                        sx={{
                          mt: 3,
                          mb: 0.5,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: item?.label || item.labelAlias,
                        }}
                      />
                    </>
                  ) : (
                    <Typography
                      sx={{ fontSize: 16, fontWeight: 500, mt: 3, mb: 0.5 }}
                    >
                      {item?.label || item.labelAlias}
                    </Typography>
                  )}

                  <Controller
                    name={item.labelAlias}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange } }) => (
                      <>
                        {item.type === "text box" && (
                          <TextField
                            variant="outlined"
                            fullWidth
                            placeholder={"Enter " + item?.labelAlias}
                            name={item.labelAlias}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            error={!!errors[item.labelAlias]}
                          />
                        )}
                        {item.type === "text area" && (
                          <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            placeholder={"Enter " + item?.labelAlias}
                            name={item.labelAlias}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            error={!!errors[item.labelAlias]}
                          />
                        )}
                        {item.type === "radio button" && (
                          <>
                            {item.options.map((itemOption, indexOption) => {
                              return (
                                <Box
                                  key={indexOption}
                                  display="flex"
                                  alignItems="center"
                                >
                                  <Radio
                                    id={itemOption}
                                    value={itemOption}
                                    checked={value === itemOption}
                                    onChange={(e) => {
                                      onChange(e);
                                      setValue(item.labelAlias, itemOption);
                                      clearErrors(item.labelAlias);
                                    }}
                                    name={item.labelAlias}
                                    color="primary"
                                  />
                                  <Typography>{itemOption}</Typography>
                                </Box>
                              );
                            })}
                          </>
                        )}

                        {item.type === "multiple selection" && (
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            options={item.options}
                            value={
                              value !== undefined && value.length > 0
                                ? value.split(",")
                                : []
                            }
                            onChange={(event, newValue) => {
                              onChange(newValue.join(","));
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder={"Select " + item?.labelAlias}
                                error={!!errors[item.labelAlias]}
                              />
                            )}
                            PaperComponent={({ children }) => (
                              <Paper
                                sx={{
                                  boxShadow: `0 0 2px 0 ${alpha(
                                    grey[500],
                                    0.24
                                  )}, -20px 20px 40px -4px ${alpha(
                                    grey[500],
                                    0.24
                                  )}`,
                                }}
                                style={{
                                  backgroundColor: "#FFFFFF",
                                }}
                              >
                                {children}
                              </Paper>
                            )}
                          />
                        )}

                        {item.type === "selection" && (
                          <>
                            {item.type === "selection" && (
                              <Select
                                id={item.labelAlias}
                                value={value || ""}
                                onChange={(e) => {
                                  onChange(e.target.value);
                                }}
                                displayEmpty
                                fullWidth
                                variant="outlined"
                                style={{
                                  minHeight: "40px",
                                }}
                                error={!!errors[item.labelAlias]}
                                renderValue={(selected) => {
                                  if (selected.length === 0) {
                                    return (
                                      <Typography
                                        sx={{
                                          color: placeholderColor,
                                          fontWeight: 400,
                                        }}
                                      >{`Select ${item.labelAlias}`}</Typography>
                                    );
                                  }
                                  return selected;
                                }}
                              >
                                {item.options.map((itemOption, indexOption) => (
                                  <MenuItem
                                    key={indexOption}
                                    value={itemOption}
                                  >
                                    {itemOption}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          </>
                        )}

                        {errors[item.labelAlias] && (
                          <Typography
                            sx={{
                              color: errorColor.main,
                              fontWeight: 400,
                              fontSize: 14,
                            }}
                          >
                            {errors[item.labelAlias].message}
                          </Typography>
                        )}
                      </>
                    )}
                  />
                </Box>
              ))}

              <Button
                type="submit"
                variant="contained"
                disabled={
                  formInfo?.totalSubmission >= formInfo?.maxSubmission ||
                  formInfo?.status === "deleted"
                }
                sx={{
                  width: 130,
                  height: 40,
                  mt: 5,
                  display: "flex",
                  mx: "auto",
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: 15,
                }}
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          </Box>
        </Card>
      </Container>
    </ThemeProvider>
  );
};

export default Forms;
